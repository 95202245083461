import ApiService from "./ApiService";

export async function apiSignKakaoCallback(data) {
    return ApiService.fetchData({
        url: process.env.REACT_APP_API_ENDPOINT + "auth/sign-kakao-test",
        method: "post",
        data,
    });
}

export async function apiSignOut(data) {
    return ApiService.fetchData({
        url: process.env.REACT_APP_API_ENDPOINT + "auth/sign-out",
        method: "post",
        data,
    });
}

export async function apiSignIn(data) {
    return ApiService.fetchData({
        url: "/sign-in",
        method: "post",
        data,
    });
}

export async function apiSignUp(data) {
    return ApiService.fetchData({
        url: "/sign-up",
        method: "post",
        data,
    });
}

export async function apiForgotPassword(data) {
    return ApiService.fetchData({
        url: "/forgot-password",
        method: "post",
        data,
    });
}

export async function apiResetPassword(data) {
    return ApiService.fetchData({
        url: "/reset-password",
        method: "post",
        data,
    });
}
