import { useMemo } from "react";
import isEmpty from "lodash/isEmpty";

function useAuthority(userAuthority = [], authority = [], emptyCheck = true) {
    const roleMatched = useMemo(() => {
        return authority.some((role) => userAuthority.includes(role));
    }, [authority, userAuthority]);

    if (
        //isEmpty(userAuthority) //&&
        isEmpty(authority) //&&
        //roleMatched === undefined
    ) {
        return emptyCheck;
    }

    return roleMatched;
}

export default useAuthority;
