import React, { memo, useMemo, lazy, Suspense } from "react";
import { Loading } from "components/shared";
import { useSelector } from "react-redux";
import {
    LAYOUT_TYPE_CLASSIC,
    LAYOUT_TYPE_MODERN,
    LAYOUT_TYPE_SIMPLE,
    LAYOUT_TYPE_STACKED_SIDE,
    LAYOUT_TYPE_DECKED,
    LAYOUT_TYPE_BLANK,
} from "constants/theme.constant";
import useAuth from "utils/hooks/useAuth";
import useDirection from "utils/hooks/useDirection";
import useLocale from "utils/hooks/useLocale";

import { useLocation } from "react-router-dom";

const layouts = {
    [LAYOUT_TYPE_CLASSIC]: lazy(() => import("./ClassicLayout")),
    [LAYOUT_TYPE_MODERN]: lazy(() => import("./ModernLayout")),
    [LAYOUT_TYPE_STACKED_SIDE]: lazy(() => import("./StackedSideLayout")),
    [LAYOUT_TYPE_SIMPLE]: lazy(() => import("./SimpleLayout")),
    [LAYOUT_TYPE_DECKED]: lazy(() => import("./DeckedLayout")),
    [LAYOUT_TYPE_BLANK]: lazy(() => import("./BlankLayout")),
};

const Layout = () => {
    const layoutType = useSelector((state) => state.theme.layout.type);
    const { authenticated } = useAuth();

    useDirection();

    useLocale();

    const location = useLocation();

    //console.log(location.pathname); // 현재 라우트의 주소

    //console.log(location.search); // 쿼리 파라미터 (?key=value)
    //console.log(location.hash); // URL 해시 (#hash)

    const AppLayout = useMemo(() => {
        if (!authenticated) {
            switch (location.pathname) {
                case "/sign-kakao-callback":
                    return lazy(() => import("./AuthLayout"));
                case "/sign-in":
                case "/sign-up":
                case "/forgot-password":
                case "/reset-password":
                case "/sign-kakao":
                default:
                    return layouts[layoutType];
            }
        }

        return layouts[layoutType];
    }, [authenticated, layoutType, location.pathname]);

    return (
        <Suspense
            fallback={
                <div className="flex flex-auto flex-col h-[100vh]">
                    <Loading loading={true} />
                </div>
            }
        >
            <AppLayout />
        </Suspense>
    );
};

export default memo(Layout);
