const appConfig = {
    apiPrefix: "",
    authenticatedEntryPath: "/artist",
    unAuthenticatedEntryPath: "/artist",
    tourPath: "/",
    locale: "ko",
    enableMock: false,
};

export default appConfig;
